// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import "./_footer.scss";
import Button from "@material-ui/core/Button";

import PrivacySetting from "../../Components/Dialog/PrivacySetting";
import LegalDataProtection from "../../Components/Dialog/LegalDataProtection";

//@component
//Footer
class Footer extends Component {
  //react state declaration
  state = {
    show: false,
    heading: "",
    content: "",
  };

  //function to update content
  updateContent = (key) => {
    //;
    this.setState({
      heading: this.dataArray[key].heading,
      content: this.dataArray[key].content,
      show: true,
    });
  };
  //function to close dialog
  hide = () => {
    //;
    this.setState({
      show: false,
    });
  };
  render() {
    return (
      <footer className="app-footer">
        <div
          className="popUp-overlay"
          style={{ display: this.state.show ? "block" : "none" }}
        ></div>
        <div
          className="popUp"
          style={{ display: this.state.show ? "block" : "none" }}
        >
          <div className="wrapper">
            <h1>{this.state.heading}</h1>
            <p>{this.state.content}</p>
          </div>
          <Button
            className="Cancel"
            variant="contained"
            onClick={() => this.hide()}
          >
            Close
          </Button>
        </div>
        <Grid container justify="space-between">
          <Grid item xs={6} py={2}>
            <span style={{ display: "block", margin: "14px 0px" }}>
              &copy; 2023 | ParkZeus | All Rights Reserved | Version. 4.0.0
            </span>
          </Grid>
          <Grid item xs={6} py={2} direction="row">
            <ul className="footer_list">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  float: "right",
                }}
              >
                <PrivacySetting displayPage="footer" />
                <LegalDataProtection displayPage="footer" />
              </div>
            </ul>
          </Grid>
        </Grid>
      </footer>
    );
  }
}

export default Footer;
